import moment from 'moment';
import { Option, OptionGroup } from '../types/olo-api';
import _ from 'lodash';
export function convertMetaDataToOptions(options: any) {
  let newArray = options;

  const metaKeys = ['display-group-name', 'display-group-option-name'];
  let startId = 555500000000;
  for (let i = 0; i < options?.length; i++) {
    let filteredOptions: any = [];
    let newOptions: any = [];
    let groupNames: any = [];
    newOptions = options[i].options.map((option: any, index: any) => {
      if (option.metadata && option.metadata?.length) {
        let filteredMeta: any = [];
        const newOptionObj = {
          ...option,
        };
        option.metadata.forEach((meta: any) => {
          if (metaKeys.includes(meta.key)) {
            if (meta.key === metaKeys[0]) {
              newOptionObj.name = meta.value;
            }
            if (meta.key === metaKeys[1]) {
              newOptionObj.groupName = meta.value;
            }
            filteredMeta.push(meta);
            if (meta.key === metaKeys[1] && !groupNames.includes(meta.value)) {
              groupNames.push(meta.value);
            }
          }
        });
        if (filteredMeta?.length === 2) {
          filteredOptions.push(newOptionObj);
          return {};
        } else {
          return option;
        }
      } else {
        return option;
      }
    });
    if (filteredOptions?.length) {
      let optionList: any = [];
      groupNames.forEach((group: string, index: number) => {
        const filterOptionsFinal = filteredOptions.filter(
          (option: any) => option.groupName === group,
        );
        const id = startId + 100;
        startId = id;
        const newParentObj = {
          adjustsparentcalories: false,
          customDropDown: true,
          adjustsparentprice: false,
          availability: {
            always: true,
            description: null,
            enddate: null,
            isdisabled: false,
            now: true,
            startdate: null,
          },
          basecalories: null,
          caloriesseparator: null,
          chainoptionid:
            filterOptionsFinal && filterOptionsFinal?.length
              ? filterOptionsFinal[0].chainoptionid
              : moment().unix() * (index + 10) + 500,
          children: true,
          cost: 0,
          costoverridelabel: null,
          displayid: null,
          fields: null,
          id: id,
          isdefault:
            (filterOptionsFinal &&
              filterOptionsFinal?.length &&
              filterOptionsFinal[0].isdefault) ||
            false,
          maxcalories: null,
          menuitemlabels: [],
          metadata: [
            {
              key: 'navigation-style',
              value: 'inline',
            },
          ],
          modifiers: [
            {
              availability: {
                always: true,
                description: null,
                enddate: null,
                now: true,
                startdate: null,
              },
              chainmodifierid: moment().unix() + 1500,
              choicequantityincrement: '1',
              description: '',
              explanationtext: null,
              hidechoicecost: false,
              id: moment().unix() + 220,
              mandatory: true,
              maxaggregatequantity: null,
              maxchoicequantity: '',
              maxselects: null,
              metadata: null,
              minaggregatequantity: null,
              minchoicequantity: '',
              minselects: null,
              options: filterOptionsFinal,
              parentchoiceid: null,
              supportschoicequantities: false,
            },
          ],
          description: '',
          name: group,
        };
        optionList.push(newParentObj);
      });
      optionList.forEach((opt: any) => {
        for (let i = 0; i < newOptions?.length - 1; i++) {
          if (Object.keys(newOptions[i])?.length === 0) {
            newOptions[i] = opt;
            break;
          }
        }
      });

      newOptions = newOptions.filter(
        (value: any) => Object.keys(value)?.length !== 0,
      );
      newArray[i].options = newOptions;
    }
  }
  return newArray;
}

export function getCostMap(simplifiedOptions?: ProductOptionGroup[]) {
  let costMap: { [key: number]: number } = {};
  simplifiedOptions?.forEach((optionGroup) => {
    optionGroup.preselectedOptions.forEach((option) => {
      costMap[option.id] = option.cost;
    });
    optionGroup.options?.forEach((option) => {
      costMap[option.id] = option.cost;
      if (option.modifiers) {
        costMap = { ...costMap, ...getCostMap(option.modifiers) };
      }
      option.dropDownOptions?.forEach((dOption: any) => {
        costMap[dOption.id] = dOption.cost;
      });
      option.preselectedOptions?.forEach((dOption: any) => {
        costMap[dOption.id] = dOption.cost;
      });
      option.popupOptions?.forEach((dOption: any) => {
        costMap[dOption.id] = dOption.cost;
      });
    });
    if (optionGroup.modifiers) {
      costMap = { ...costMap, ...getCostMap(optionGroup.modifiers) };
    }
  });
  return costMap;
}

export function getAllChainOptionIds(
  simplifiedOptions?: ProductOptionGroup[],
): number[] {
  return (
    simplifiedOptions?.flatMap((optionGroup) => {
      return [
        ...(optionGroup.options?.flatMap((option) => {
          return [
            option.chainoptionid,
            ...(option.dropDownOptions?.flatMap(
              (dOption) => dOption.chainoptionid,
            ) ?? []),
            ...(option.popupOptions?.flatMap(
              (popupOption) => popupOption.chainoptionid,
            ) ?? []),
            ...(option.modifiers?.flatMap((modifier) =>
              getAllChainOptionIds([modifier]),
            ) ?? []),
          ];
        }) ?? []),
        ...(optionGroup.modifiers
          ? getAllChainOptionIds(optionGroup.modifiers)
          : []),
      ];
    }) ?? []
  );
}

export const getValueFor = (key: string, metadata: any) => {
  if (metadata) {
    for (const meta of metadata) {
      if (meta.key === key) {
        return meta.value;
      }
    }
  }
  return undefined;
};

export const isNavigationStyle = (option: any, style: 'popup' | 'inline') => {
  const value = getValueFor('navigation-style', option.metadata);
  return value === style;
};

const isRemovable = (option: any) => {
  const value = getValueFor('IsRemoveable', option.metadata);
  return value === 'true';
};
const getDropdownJson = (parentOption: Option, parentGroup: OptionGroup) => {
  let dropDownOptions: any = null;
  if (isNavigationStyle(parentOption, 'inline')) {
    dropDownOptions = parentOption.modifiers[0].options.map((option: any) => ({
      id: option.id,
      name: option.name,
      isRemovable: isRemovable(option),
      chainoptionid: option.chainoptionid,
      isdefault: option.isdefault,
      cost: option.cost,
      parentId: parentGroup.id,
      metadata: option.metadata,
      basecalories: option.basecalories,
      maxcalories: option.maxcalories,
      selected: option.isdefault,
      enabled: true,
      count: 1,
      maxCount: parentGroup.maxselects,
    }));
    if (dropDownOptions.filter((op: any) => op.isdefault).length === 0) {
      dropDownOptions.filter((op: any) => !op.isRemovable)[0].isdefault = true;
    }
  }

  return { dropDownOptions };
};

const getPopupOptions = (parentOption: Option, parentGroup: any) => {
  let popupOptions: any = null;
  let preselectedOptions: any;
  if (isNavigationStyle(parentOption, 'popup') && parentOption.modifiers) {
    let optionsForProcess = parentOption.modifiers[0].options;
    if (
      parentOption.modifiers[0].options.length === 1 &&
      parentOption.modifiers[0].options[0].modifiers.length
    ) {
      optionsForProcess =
        parentOption.modifiers[0].options[0].modifiers[0].options;
      preselectedOptions = [
        {
          id: parentOption.modifiers[0].options[0].id,
          name: parentOption.modifiers[0].options[0].name,
          cost: parentOption.modifiers[0].options[0].cost,
        },
      ];
    }
    popupOptions = optionsForProcess.map((option: any) => ({
      id: option.id,
      name: option.name,
      chainoptionid: option.chainoptionid,
      isdefault: option.isdefault,
      cost: option.cost,
      parentId: parentGroup.id,
      metadata: option.metadata,
      basecalories: option.basecalories,
      maxcalories: option.maxcalories,
      selected: option.isdefault,
      enabled: true,
      count: 1,
      maxCount: parentGroup.maxselects,
    }));
  }

  return { popupOptions, preselectedOptions };
};

const getAllOptionNames = (
  options?: ProductOption[],
  prefix: string = '',
): any => {
  return (
    options?.flatMap((option) => [
      `${prefix}(${option.name})`,
      ...(option.dropDownOptions?.map(
        (dOption) => `${prefix}(${option.name}).(${dOption.name})`,
      ) ?? []),
      ...getAllOptionNamesFromModifiers(
        option.modifiers,
        `${prefix}(${option.name}).`,
      ),
    ]) ?? []
  );
};

const getAllOptionNamesFromModifiers = (
  optionsGroups?: any[],
  prefix: string = '',
): any => {
  return (
    optionsGroups?.flatMap((optionsGroup) => [
      ...optionsGroup.preselectedOptions.map(
        (o: any) => `${prefix}(${o.name})`,
      ),
      ...getAllOptionNames(optionsGroup.options, prefix),
      ...getAllOptionNamesFromModifiers(optionsGroup.modifiers, prefix),
    ]) ?? []
  );
};

const getAllOptionIds = (
  options?: ProductOption[],
  key: 'id' | 'name' = 'id',
): any => {
  return (
    options?.flatMap((option) => [
      option[key],
      ...(option.dropDownOptions?.map((dOption) => dOption[key]) ?? []),
      ...getAllOptionIdsFromModifiers(option.modifiers, key),
    ]) ?? []
  );
};

export const getAllOptionIdsFromModifiers = (
  optionsGroups?: any[],
  key: 'id' | 'name' = 'id',
): any => {
  return (
    optionsGroups?.flatMap((optionsGroup) => [
      ...optionsGroup.preselectedOptions.map((o: any) => o[key]),
      ...getAllOptionIds(optionsGroup.options, key),
      ...getAllOptionIdsFromModifiers(optionsGroup.modifiers, key),
    ]) ?? []
  );
};

export type ProductOptionGroup = {
  id: number;
  type: 'simple' | 'optionsWithCustomization' | 'customization';
  name: string;
  mandatory: boolean;
  maxCount?: number;
  preselectedOptions: { id: number; name: string; cost: number }[];
  defaultSelectedIds: number[];
  options?: ProductOption[];
  isSubContainer: boolean;
  willActivate: number;
  isdefault?: boolean;
  chooseButtonTitle?: string;
  showCustomization?: boolean;
  sameAsArray?: {
    id: number;
    name: string;
    helperMap: { [key: number]: number };
  }[];
  modifiers?: ProductOptionGroup[];
  isComplex?: boolean;
};

export type ProductOption = {
  id: number;
  name: string;
  chainoptionid: number;
  selectedOption?: number;
  isdefault: boolean;
  cost: number;
  parentId?: number;
  metadata?: any[];
  mandatory: boolean;
  basecalories?: string;
  maxcalories?: string;
  maxCount?: number;
  otherGroupOptionIds: number[];
  dropDownOptions?: ProductOption[];
  popupOptions?: ProductOption[];
  preselectedOptions?: { id: number; name: string; cost: number }[];
  modifiers?: ProductOptionGroup[];
  isLast: boolean;
  isRemovable?: boolean;
  willActivate: number;
};

export const areAllValidSelections = (
  selectedIds: { [key: number]: number },
  optionGroups?: ProductOptionGroup[],
  groupId?: number,
) => {
  const groups = groupId
    ? optionGroups?.filter((o) => o.id === groupId)
    : optionGroups;
  let isValid = true;
  groups?.forEach((optionGroup) => {
    if (optionGroup.modifiers) {
      isValid =
        isValid && areAllValidSelections(selectedIds, optionGroup.modifiers);
    }
    if (optionGroup.mandatory) {
      if (optionGroup.options?.length) {
        let isSingleOptionSelected = false;
        optionGroup.options?.forEach((option) => {
          if (selectedIds[option.id] > 0) {
            isSingleOptionSelected = areAllValidSelections(
              selectedIds,
              option.modifiers,
            );
          }
        });
        isValid = isValid && isSingleOptionSelected;
      }
    } else if (optionGroup.maxCount) {
      const selectedOptions =
        optionGroup.options?.reduce(
          (acc, option) => acc + (selectedIds[option.id] ?? 0),
          0,
        ) ?? 0;
      if (selectedOptions < optionGroup.maxCount) {
        isValid = false;
      }
    } else {
      optionGroup.options?.forEach((option) => {
        if (selectedIds[option.id] > 0) {
          const isSubOptionValid = areAllValidSelections(
            selectedIds,
            option.modifiers,
          );
          isValid = isValid && isSubOptionValid;
        }
      });
    }
  });
  return isValid;
};

export const getAllValidSelectedIds = (
  selectedIds: { [key: number]: number },
  optionGroups?: ProductOptionGroup[],
  groupId?: number,
) => {
  const groups = groupId
    ? optionGroups?.filter((o) => o.id === groupId)
    : optionGroups;
  let validSelectedIds: number[] = [];
  groups?.forEach((optionGroup) => {
    validSelectedIds = [
      ...validSelectedIds,
      ...optionGroup.preselectedOptions.map((o) => o.id),
    ];
    if (optionGroup.modifiers) {
      validSelectedIds = [
        ...validSelectedIds,
        ...getAllValidSelectedIds(selectedIds, optionGroup.modifiers),
      ];
    }
    optionGroup.options?.forEach((option) => {
      const count = selectedIds[option.id];
      if (count) {
        for (let i = 0; i < count; i++) {
          validSelectedIds.push(option.id);
          // if (option.dropDownOptions) {
          //   validSelectedIds = [
          //     ...validSelectedIds,
          //     ...option.dropDownOptions
          //       .filter((dOption) => selectedIds[dOption.id])
          //       .map((dOption) => dOption.id),
          //   ];
          // }
        }
        if (option.dropDownOptions) {
          validSelectedIds = [
            ...validSelectedIds,
            ...option.dropDownOptions
              .filter((dOption) => selectedIds[dOption.id])
              .map((dOption) => dOption.id),
          ];
        }

        if (option.popupOptions) {
          validSelectedIds = [
            ...validSelectedIds,
            ...(option.preselectedOptions?.map((preOption) => preOption.id) ??
              []),
            ...option.popupOptions
              .filter((dOption) => selectedIds[dOption.id])
              .map((dOption) => dOption.id),
          ];
        }

        if (option.modifiers) {
          validSelectedIds = [
            ...validSelectedIds,
            ...getAllValidSelectedIds(selectedIds, option.modifiers),
          ];
        }
      }
    });
  });
  return validSelectedIds;
};

export const getGroupsSelection = (
  selectedIds: { [key: number]: number },
  optionGroups?: ProductOptionGroup[],
  groupId?: number,
) => {
  const groups = groupId
    ? optionGroups?.filter((o) => o.id === groupId)
    : optionGroups;
  const allIds = getAllOptionIdsFromModifiers(groups);
  const fitleredKeys = Object.keys(selectedIds)
    .filter((key) => allIds.includes(+key))
    .map((key) => +key);
  return fitleredKeys.reduce((acc: { [key: number]: number }, key) => {
    acc[key] = selectedIds[key];
    return acc;
  }, {});
};

export const getAllDefaultSelectedIds = (
  optionGroups?: ProductOptionGroup[],
) => {
  let defaultSelectedIds: number[] = [];
  optionGroups?.forEach((optionGroup) => {
    if (optionGroup.modifiers) {
      defaultSelectedIds = [
        ...defaultSelectedIds,
        ...getAllDefaultSelectedIds(optionGroup.modifiers),
      ];
    }
    optionGroup.options?.forEach((option) => {
      if (option.isdefault) {
        defaultSelectedIds.push(option.id);
      }
      if (option.dropDownOptions) {
        for (const dOption of option.dropDownOptions) {
          if (dOption.isdefault) {
            defaultSelectedIds.push(dOption.id);
          }
        }
      }

      if (option.modifiers) {
        defaultSelectedIds = [
          ...defaultSelectedIds,
          ...getAllDefaultSelectedIds(option.modifiers),
        ];
      }
    });
  });
  return defaultSelectedIds;
};

export const getNewMenuOptionGroups = (
  optionGroups: OptionGroup[],
  selectedIds?: number[],
) => {
  const simplifiedOptionGroups = toSimplifiedJson(optionGroups, undefined);
  if (simplifiedOptionGroups) {
    const previousComplexOptionGroupsHelperMap: {
      [key: number]: {
        name: string;
        allNamesMap: { [key: string]: number };
      };
    } = {};

    let sameAsArray: any = [];
    for (let i = 0; i < simplifiedOptionGroups.length; i++) {
      if (
        simplifiedOptionGroups[i].options?.[0]?.modifiers?.length === 1 &&
        simplifiedOptionGroups[i].options?.[0]?.modifiers?.[0]?.type ===
          'customization'
      ) {
        simplifiedOptionGroups[i].type = 'optionsWithCustomization';
        setWillActivateForAllChildrens(
          simplifiedOptionGroups[i],
          simplifiedOptionGroups[i].id,
        );
        simplifiedOptionGroups[i].sameAsArray = [
          ...sameAsArray.map((s: any) => ({ ...s })),
        ];
        for (let j = 0; j < simplifiedOptionGroups[i].options!.length; j++) {
          if (
            simplifiedOptionGroups[i].options![j].modifiers!.length &&
            simplifiedOptionGroups[i].options![j].modifiers![0].type ===
              'customization'
          ) {
            simplifiedOptionGroups[i].options![j].modifiers![0].isComplex =
              true;
          }
        }

        const allIds = getAllOptionIds(simplifiedOptionGroups[i].options, 'id');
        const allUniqueNames = getAllOptionNames(
          simplifiedOptionGroups[i].options,
        );

        const allNamesMap = allUniqueNames.reduce(
          (acc: any, name: any, index: number) => {
            acc[name] = allIds[index];
            return acc;
          },
          {},
        );

        for (const key in previousComplexOptionGroupsHelperMap) {
          if (
            allUniqueNames.length ===
            _.intersection(
              allUniqueNames,
              Object.keys(
                previousComplexOptionGroupsHelperMap[key].allNamesMap,
              ),
            ).length
          ) {
            const helperMap: any = {};
            for (const name in allNamesMap) {
              helperMap[
                +previousComplexOptionGroupsHelperMap[key].allNamesMap[name]
              ] = +allNamesMap[name];
            }

            simplifiedOptionGroups[i].sameAsArray?.push({
              id: +key,
              name: previousComplexOptionGroupsHelperMap[key].name,
              helperMap,
            });
          }
        }

        previousComplexOptionGroupsHelperMap[simplifiedOptionGroups[i].id] = {
          name: simplifiedOptionGroups[i].name,
          allNamesMap,
        };
      }
    }
  }
  const defaultSelectedIds = getAllDefaultSelectedIds(simplifiedOptionGroups);
  return {
    options: simplifiedOptionGroups,
    defaultSelectedIds,
    selectedIds: selectedIds ?? defaultSelectedIds,
  };
};

const setWillActivateForAllChildrens = (
  optionGroup: ProductOptionGroup,
  activationId: number,
) => {
  optionGroup.willActivate = activationId;
  optionGroup.modifiers?.forEach((modifier) => {
    setWillActivateForAllChildrens(modifier, activationId);
  });
  optionGroup.options?.forEach((option) => {
    option.willActivate = activationId;
    option.modifiers?.forEach((modifier) => {
      setWillActivateForAllChildrens(modifier, activationId);
    });
  });
};

export const toSimplifiedJson = (
  optionGroups?: OptionGroup[],
  parentId?: number,
  isSubContainer: boolean = false,
  willActivate?: number,
) => {
  return optionGroups?.map((optionGroup) =>
    simpifyOptionGroup(optionGroup, parentId, isSubContainer, willActivate),
  );
};

const simpifyOptionGroup = (
  optionGroup: OptionGroup,
  parentId?: number,
  isSubContainer: boolean = false,
  willActivate?: number,
): ProductOptionGroup => {
  //CustomizationModifier
  if (optionGroup.description === 'As is or Customize?') {
    const customizedOption = optionGroup.options.find(
      (option) => option.name === 'Customize',
    );
    return {
      id: optionGroup.id,
      type: 'customization',
      name: 'Customize',
      isSubContainer,
      willActivate: willActivate ?? optionGroup.id,
      preselectedOptions: [
        {
          id: customizedOption!.id,
          name: customizedOption!.name,
          cost: customizedOption!.cost,
        },
      ],
      mandatory: true,
      isdefault: optionGroup.options[1].isdefault,
      defaultSelectedIds: [],
      chooseButtonTitle: getValueFor('ChooseButtonTitle', optionGroup.metadata),
      modifiers: toSimplifiedJson(
        customizedOption!.modifiers,
        customizedOption!.id,
        true,
        willActivate ?? optionGroup.id,
      ),
    };
  } else {
    const options = optionGroup.options;
    const ids = options.map((option) => option.id);
    return {
      id: optionGroup.id,
      type: 'simple',
      name: optionGroup.description,
      isSubContainer,
      maxCount: optionGroup.maxselects ? +optionGroup.maxselects : undefined,
      willActivate: isSubContainer
        ? willActivate ?? optionGroup.id
        : optionGroup.id,
      mandatory: optionGroup.mandatory,
      defaultSelectedIds: options
        .filter((option) => option.isdefault)
        .map((option) => option.id),
      preselectedOptions: [],
      chooseButtonTitle: getValueFor('ChooseButtonTitle', optionGroup.metadata),
      options: options.map((option, index) => ({
        id: option.id,
        name: option.name,
        chainoptionid: option.chainoptionid,
        isdefault: option.isdefault,
        cost: option.cost,
        parentId: parentId,
        metadata: option.metadata,
        basecalories: option.basecalories,
        mandatory: optionGroup.mandatory,
        maxcalories: option.maxcalories,
        otherGroupOptionIds: ids,
        enabled: true,
        count: 1,
        isLast: index === options.length - 1,
        willActivate: isSubContainer
          ? willActivate ?? optionGroup.id
          : optionGroup.id,
        maxCount: optionGroup.maxselects ? +optionGroup.maxselects : undefined,
        ...getPopupOptions(option, optionGroup),
        ...getDropdownJson(option, optionGroup),
        modifiers: !(
          isNavigationStyle(option, 'inline') ||
          isNavigationStyle(option, 'popup')
        )
          ? toSimplifiedJson(option.modifiers, option.id)
          : undefined,
      })),
    };
  }
};
