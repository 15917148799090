
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Theme,
  Button,
  Grid,
  Divider,
  Dialog,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/imgs/Rubios-Logo.png';
import cartIcon from '../../assets/imgs/cart-icon.svg';
import cartIconMobile from '../../assets/imgs/cart-icon-mobile.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Cart from '../cart';
import Upsells from '../cart/upsells';
import { useDispatch, useSelector } from 'react-redux';
import RightMenuBar from '../right-menu-bar';
import { isLoginUser } from '../../helpers/auth';
import './index.css';
import { useLocation } from 'react-router-dom';
import nameIcon from '../../assets/imgs/nameicon.svg';
import { updateMenu } from '../../redux/actions/restaurant';
import { useStateContext } from '../../pages/scan-and-register/stateContext';
const useStyles = makeStyles((theme: Theme) => ({
  navBar: {
    backgroundColor: '#fff !important',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%) !important',
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  logoImg: {
    display: 'inline-block',
    padding: '9px 15px 12px 28px',
    [theme.breakpoints.down('lg')]: {
      padding: '14px 0 14px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '14px 0 14px 20px',
    },
    '& img': {
      width: '90%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  },
  icon: {
    color: theme.palette.primary.main + ' !important',
  },
  menuLink: {
    textTransform: 'uppercase',
    display: 'block',
    paddingTop: '28px',
    paddingRight: '12px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '27px',
      paddingBottom: '22px',
      paddingRight: '12px',
      fontSize: '11pt',

    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '27px',
      paddingBottom: '22px',
      paddingRight: '12px',
      fontSize: '11pt',
    },
    color: theme.palette.primary.main,
    fontFamily: "'Sunborn-Sansone'!important",
    textDecoration: 'none',
    fontSize: '12pt',
    textAlign: 'center',
    '&:hover': {
      color: '#0073BD',
    },
    transition: 'color 0.5s ease',
  },
  menuLinkview: {
    textTransform: 'uppercase',
    display: 'block',
    whiteSpace: "nowrap",
    [theme.breakpoints.up('md')]: {
      fontSize: '11pt',

    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11pt',
    },
    fontFamily: "'GritSans-Bold'!important",
    textDecoration: 'none',
    fontSize: '14px',
    color: '#006DB7',
    textAlign: 'center',
    '&:hover': {
      color: '#0073BD',
    },
    transition: 'color 0.5s ease',
  },
  menuItemLink: {
    textTransform: 'uppercase',
    display: 'block',
    paddingTop: '25px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '27px',
      paddingBottom: '22px',
      fontSize: '14px',
    },
    color: theme.palette.primary.main,
    fontFamily: "'Sunborn-Sansone'!important",
    textDecoration: 'none',
    fontSize: '17px',
    textAlign: 'center',
    '&:hover': {
      color: '#0073BD',
    },
    transition: 'color 0.5s ease',
  },
}));
let fromEditOrder: Boolean = false;
const getBasketCount = (basket: any) => {
  var count = 0;
  basket.products.map((item: any) => {
    count = count + item.quantity;
  });
  return count;
};
export const handleCart = () => {
  fromEditOrder = true;
};

const Header = (props: any) => {
  const {
    removeCart,
    hideLoginPanel,
    removeCartForLocation,
    hideLoginedPanel,
    closeDrawer
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { state, updateSharedState } = useStateContext();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCart, setShowCart] = useState(false);
  const [showUpsells, setShowUpsells] = useState(false);
  const [upsellsType, setUpsellsType] = useState('');
  const basketObj = useSelector((state: any) => state.basketReducer);
  const { providerToken } = useSelector((state: any) => state.providerReducer);
  const { isMenu, restaurant, orderType } = useSelector(
    (state: any) => state.restaurantInfoReducer,
  );
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);

  let query = useQuery();

  useEffect(() => {
    if (query.get("cart")) {
      setShowCart(true);
      const timerId = setTimeout(() => {
        const url = new URL(window.location.href);
        url?.searchParams?.delete('cart');
        window.history.pushState(null, '', url?.toString());
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    }
    else {
      setShowCart(false);
    }
  }, [query.get("cart")])


  const handleShowCart = () => {
    setShowAccountMenu(false);
    if (fromEditOrder) {
      fromEditOrder = false;
      setShowCart(false);
    } else {
      setShowCart(!showCart);
    }
    if (showCart) {
      setShowUpsells(false);
      setUpsellsType('');
    }
  };

  const handleUpsells = (type: string) => {
    if (type === '') {
      setShowUpsells(false);
    } else {
      setShowUpsells(true);
    }
    setUpsellsType(type);
  };
  return (
    <>
      <AppBar id={'page-header'} position="sticky" className={classes.navBar}>
        <Toolbar
          sx={{
            alignItems: { sm: 'inherit' },
            padding: { xs: '0 !important' },
            height: '79px',
          }}
        >
          <Typography variant="body1" className={classes.logo}>
            <span
              className={classes.logoImg}
            >
              <a href={process.env.REACT_APP_RUBIOS_HOME_PAGE}>
                <img
                  role={'button'}
                  tabIndex={0}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      navigate(`${process.env.REACT_APP_RUBIOS_HOME_PAGE}`);
                    }
                  }}
                  aria-label="Rubio's Cosatal Grill Logo"
                  src={logo}
                  style={{ display: 'flex' }}
                  alt="Rubio's Cosatal Grill"
                  title="Rubio's Cosatal Grill Logo"
                />
              </a>
            </span>
            {isLoginUser() && providerToken?.first_name
              ? !hideLoginedPanel && (
                <Grid
                  container
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                      md: 'block',
                      lg: 'block',
                    },
                    padding: "25px",
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: 'auto',
                    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
                  }}
                  role={'button'}
                  aria-label={'Account Menu'}
                  tabIndex={0}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      setShowAccountMenu(!showAccountMenu);
                    }
                  }}
                  onClick={() => {
                    setShowAccountMenu(!showAccountMenu);
                  }}
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'block',
                        maxWidth: { sm: 'auto', lg: 'auto' },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: "'GritSans-Bold'!important",
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontSize: '14px !important',
                        fontWeight: '700',
                        marginTop: '9px',
                        color: "#0073BD",
                      }}
                    >
                      Hi {(isLoginUser() && providerToken?.first_name) || ''} {(!isLoginUser() && 'THERE') || ''}
                      !
                      <img src={nameIcon} style={{ marginLeft: '10px', marginBottom: '2px' }} />
                    </Typography>
                  </Grid>
                </Grid>
              )
              : !hideLoginPanel && (
                <Grid
                  container
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                      md: 'block',
                      lg: 'block',
                    },
                    padding: "25px",
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: 'auto',
                    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
                  }}
                  role={'button'}
                  aria-label={'Account Menu'}
                  tabIndex={0}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      setShowAccountMenu(!showAccountMenu);
                    }
                  }}
                  onClick={() => {
                    setShowAccountMenu(!showAccountMenu);
                  }}
                >
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'block',
                        maxWidth: { sm: 'auto', lg: 'auto' },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: "'GritSans-Bold'!important",
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontSize: '14px !important',
                        fontWeight: '700',
                        marginTop: '9px',
                        color: "#0073BD",
                      }}
                    >
                      Hi {(isLoginUser() && providerToken?.first_name) || ''} {(!isLoginUser() && 'THERE') || ''}
                      !
                      <img src={nameIcon} style={{ marginLeft: '10px', marginBottom: '2px' }} />
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Typography>
          {isMobile ? (
            <>
              {restaurant && orderType && (
                <Link
                  to={restaurant ? '/menu/' + restaurant.slug : '/'}
                  className={
                    window.location.pathname === '/login' ||
                      window.location.pathname === '/register'
                      ? classes.menuItemLink
                      : classes.menuLink
                  }
                  title="Menu"
                  onClick={() => { setShowAccountMenu(false); dispatch(updateMenu(true)) }}
                >
                  Menu
                </Link>
              )}
              {isLoginUser() && providerToken?.first_name ? (
                <Grid
                  container
                  sx={{
                    background: '#0075BF',
                    alignItems: 'center',
                    fontFamily: "'Sunborn-Sansone'!important",
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontSize: '14px',
                    height: '80px',
                    width: '65px',
                  }}
                  role={'button'}
                  aria-label={'Account Menu'}
                  tabIndex={0}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      setShowAccountMenu(!showAccountMenu);
                    }
                  }}
                  onClick={() => {
                    setShowAccountMenu(!showAccountMenu);
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <img
                      src={require('../../assets/imgs/user-icon.png')}
                      alt="Profile Icon"
                    />
                  </Grid>
                </Grid>
              ) : (
                !hideLoginPanel && (
                  <Grid
                    container
                    sx={{
                      background: '#0075BF',
                      alignItems: 'center',
                      fontFamily: "'Sunborn-Sansone'!important",
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      height: '80px',
                      width: '65px',
                    }}
                    role={'button'}
                    aria-label={'Sign In'}
                    tabIndex={0}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        navigate('/login');
                      }
                    }}
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <img
                        src={require('../../assets/imgs/user-icon.png')}
                        alt="Login Icon"
                      />
                    </Grid>
                  </Grid>
                )
              )}
              {!removeCart && !removeCartForLocation && (
                <div
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    textAlign: 'center',
                    width: '72px',
                    backgroundColor: 'white',
                    height: '70px',
                    display: 'flex',

                  }}
                  aria-label="Open the cart"
                  role={'button'}
                  onClick={handleShowCart}
                  tabIndex={0}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      handleShowCart();
                    }
                  }}
                >
                  <img
                    src={cartIconMobile}
                    alt="Cart Icon"
                    style={{
                      width: '32px',
                      display: 'block',
                      margin: 'auto',
                    }}
                    title="Cart Icon"
                  />
                  <div
                    style={{
                      color: 'rgb(34, 76, 101)',
                      position: 'absolute',
                      margin: 'auto',
                      inset: 'auto',
                      display: 'inline-block',
                      paddingTop: '34px',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      textAlign: 'center',
                      fontSize: '13px',
                      fontFamily: "'Sunborn-Sansone'!important",
                    }}
                  >
                    {basketObj.basket &&
                      basketObj.basket.products?.length > 0 &&
                      getBasketCount(basketObj.basket)}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}>

                {restaurant && orderType && (
                  <Grid sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '214px',
                    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
                  }}>
                    <Link

                      to={restaurant ? '/menu/' + restaurant.slug : '/'}
                      className={classes.menuLinkview}
                      title="View Menu"
                      onClick={() => setShowAccountMenu(false)}
                    >
                      View Menu
                    </Link>
                  </Grid>
                )}
              </Grid>
              {!removeCart && (
                <Button
                  component="div"
                  onClick={handleShowCart}
                  aria-label="Open the cart"
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      handleShowCart();
                    }
                  }}
                  sx={{
                    paddingRight: { xs: '20px', md: '30px' },
                    paddingLeft: { xs: '20px', md: '30px' },
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: "0px !important",
                    backgroundColor: '#006DB7',
                    minWidth: '139px',
                    '&:hover': {
                      backgroundColor: '#006DB7',
                    },
                  }}
                >
                  <img
                    src={cartIcon}
                    style={{ width: '36px' }}

                    alt="Cart Icon"
                    title="Cart Icon"
                  />
                  <div
                    style={{
                      position: 'absolute',
                      margin: 'auto',
                      inset: 'auto',
                      fontFamily: "'Sunborn-Sansone'!important",
                      display: 'inline',
                      paddingTop: '10px',
                      color: "white"
                    }}
                  >
                    {basketObj.basket &&
                      basketObj.basket.products?.length > 0 &&
                      getBasketCount(basketObj.basket)}
                  </div>
                </Button>
              )}
            </>
          )}
        </Toolbar>
      </AppBar >
      {(fromEditOrder && <Cart showCart={handleShowCart} />) ||
        (showCart && (
          <Dialog
            open={showCart}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: '0' }}
          >
            {showUpsells &&
              upsellsType !== '' &&
              basketObj &&
              basketObj.basket &&
              basketObj.basket.products &&
              basketObj.basket.products?.length && (
                <Upsells
                  upsellsType={upsellsType}
                  showCart={() => handleUpsells('')}
                />
              )}
            <Cart
              upsellsType={upsellsType}
              showCart={handleShowCart}
              handleUpsells={handleUpsells}
            />
          </Dialog>
        ))
      }
      {
        (showAccountMenu || (isMobile && state.sharedState === true)) && (
          <RightMenuBar
            closeDrawer={setShowAccountMenu}
            removeCart={removeCart}
          />
        )
      }
    </>
  );
};
export default Header;
